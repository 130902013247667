<script lang="ts">
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { Editor } from "@tiptap/core";
  import StarterKit from "@tiptap/starter-kit";
  import Link from "@tiptap/extension-link";
  import Underline from "@tiptap/extension-underline";
  import TextAlign from "@tiptap/extension-text-align";
  import TextStyle from "@tiptap/extension-text-style";
  import Color from "@tiptap/extension-color";
  import Highlight from "@tiptap/extension-highlight";
  import Image from "@tiptap/extension-image";
  import Youtube from "@tiptap/extension-youtube";
  import Table from "@tiptap/extension-table";
  import TableRow from "@tiptap/extension-table-row";
  import TableCell from "@tiptap/extension-table-cell";
  import TableHeader from "@tiptap/extension-table-header";
  import Document from "@tiptap/extension-document";
  import Paragraph from "@tiptap/extension-paragraph";
  import Text from "@tiptap/extension-text";
  import Gapcursor from "@tiptap/extension-gapcursor";
  import Dropcursor from "@tiptap/extension-dropcursor";
  import History from "@tiptap/extension-history";
  import Placeholder from "@tiptap/extension-placeholder";
  import CharacterCount from "@tiptap/extension-character-count";
  import DragHandle from "@tiptap-pro/extension-drag-handle";
  import { PreTableTitleDiv } from "./extensions/PreTableTitleDiv";
  import { FireList } from "./extensions/FireList";
  import { ProsList } from "./extensions/ProsList";
  import { ConsList } from "./extensions/ConsList";
  import { EditorView, keymap, lineNumbers } from "@codemirror/view";
  import { EditorState } from "@codemirror/state";
  import { html } from "@codemirror/lang-html";
  import { oneDark } from "@codemirror/theme-one-dark";
  import { defaultKeymap } from "@codemirror/commands";
  import { indentUnit } from "@codemirror/language";
  import MenuBar from "./MenuBar.svelte";
  import CompactMenuBar from "./CompactMenuBar.svelte";
  import HardBreak from "@tiptap/extension-hard-break";

  export let text: string = "";
  export let placeholder: string = "";
  export let compactMode: boolean = false;
  export let maxLength: number | null = null;

  let element: HTMLElement;
  let editor: Editor;
  let showHtml = false;
  let htmlContent = "";
  let codeEditor: EditorView;
  let codeEditorElement: HTMLElement;
  let isTableActive = false;
  let showFullMenu = !compactMode;
  let characterCount = 0;
  let wordCount = 0;

  const dispatch = createEventDispatcher();

  const updateCounts = () => {
    if (editor?.storage.characterCount) {
      characterCount = editor.storage.characterCount.characters();
      wordCount = editor.storage.characterCount.words();
    }
  };

  const formatHtml = (html: string) => {
    const formatted = html
      .replace(/></g, ">\n<")
      .replace(/(<div[^>]*>|<\/div>|<p>|<\/p>|<span[^>]*>|<\/span>)/g, "$1\n")
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0)
      .map((line, _, array) => {
        let indent = 0;
        for (let i = 0; i < array.length; i++) {
          if (array[i] === line) break;
          if (array[i].startsWith("</")) indent--;
          if (!array[i].startsWith("</") && array[i].endsWith(">")) indent++;
        }
        return "  ".repeat(Math.max(0, indent)) + line;
      })
      .join("\n");
    return formatted;
  };

  const initCodeEditor = () => {
    if (codeEditor) {
      codeEditor.destroy();
    }

    codeEditor = new EditorView({
      state: EditorState.create({
        doc: htmlContent,
        extensions: [
          lineNumbers(),
          html(),
          oneDark,
          keymap.of(defaultKeymap),
          indentUnit.of("  "),
          EditorView.theme({
            "&": { height: "400px" },
            ".cm-scroller": { overflow: "auto" },
            ".cm-content": { whiteSpace: "pre-wrap" },
            ".cm-line": { padding: "0 3px" },
          }),
        ],
      }),
      parent: codeEditorElement,
    });
  };

  const initEditor = () => {
    if (editor) {
      editor.destroy();
    }

    const CustomTable = Table.extend({
      renderHTML({ HTMLAttributes }) {
        return [
          "div",
          { class: "table-container-wrapper", style: "false" },
          ["div", { class: "table-container" }, ["table", { ...HTMLAttributes, class: "table-element" }, 0]],
        ];
      },
    });

    editor = new Editor({
      element: element,
      extensions: [
        StarterKit.configure({
          // Disable the extensions we're adding separately
          dropcursor: false,
          gapcursor: false,
          document: false,
          paragraph: false,
          text: false,
          history: false,
        }),
        History.configure({
          depth: 100,
          newGroupDelay: 500,
        }),
        Placeholder.configure({
          placeholder: ({ node }) => {
            if (node.type.name === "heading") {
              return "What's the title?";
            }
            return placeholder || "Write something …";
          },
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: "text-blue-500 underline",
            rel: null,
            target: null,
          },
        }),
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        TextStyle,
        Color,
        Highlight,
        Image.configure({
          inline: true,
        }),
        Youtube.configure({
          controls: true,
          nocookie: true,
        }),
        CustomTable.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Document,
        Paragraph,
        Text,
        Gapcursor,
        Dropcursor,
        CharacterCount.configure({
          limit: maxLength,
          mode: "textSize",
        }),
        DragHandle.configure({
          render: () => {
            const element = document.createElement("button");
            element.type = "button";
            element.innerHTML = `
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16">
                                <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                </svg>
                        `;
            element.className = "drag-handle";
            return element;
          },
          tippyOptions: {
            duration: [300, 200],
            placement: "left-start",
            arrow: true,
            animation: "shift-away",
          },
        }),
        PreTableTitleDiv,
        FireList,
        ProsList,
        ConsList,
      ],
      content: text,
      onUpdate: ({ editor }) => {
        text = editor.getHTML();
        updateCounts();
        dispatch("change", { content: text });
      },
      onSelectionUpdate: ({ editor }) => {
        isTableActive = editor.isActive("table");
      },
      onCreate: ({ editor }) => {
        updateCounts();
      },
    });
  };

  const toggleHtml = () => {
    if (showHtml) {
      text = codeEditor.state.doc.toString();
      showHtml = false;
      setTimeout(() => {
        initEditor();
      }, 0);
    } else {
      htmlContent = formatHtml(editor.getHTML());
      showHtml = true;
      setTimeout(() => {
        initCodeEditor();
      }, 0);
    }
  };

  const toggleMenu = () => {
    showFullMenu = !showFullMenu;
  };

  onMount(() => {
    initEditor();
  });

  onDestroy(() => {
    if (editor) {
      editor.destroy();
    }
    if (codeEditor) {
      codeEditor.destroy();
    }
  });
</script>

<div class="editor-wrapper">
  {#if editor}
    {#if compactMode && !showFullMenu}
      <CompactMenuBar {editor} {showFullMenu} on:toggleMenu={toggleMenu} />
    {:else}
      <MenuBar {editor} {compactMode} {showFullMenu} on:toggleMenu={toggleMenu} on:toggleHtml={toggleHtml} />
    {/if}
  {/if}

  {#if showHtml}
    <div class="html-editor" bind:this={codeEditorElement} />
  {:else}
    <div bind:this={element} class="editor-content" />
  {/if}

  <div class="editor-footer">
    <div class="character-count">
      {characterCount} characters
      {#if maxLength}
        <span class="limit-info">
          ({maxLength - characterCount} remaining)
        </span>
      {/if}
      · {wordCount} words
    </div>
  </div>
</div>

<style>
  .editor-content {
    font-weight: 300;
  }
  :global(.editor-content div[contenteditable="true"]) {
    font-size: 16px;
  }

  .editor-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  :global(.ProseMirror) {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
  }

  :global(.drag-handle) {
    position: absolute;
    left: -24px;
    background: white !important;
    border: 1px solid #ccc !important;
    color: #747f8f !important;
    cursor: grab !important;
    padding: 4px !important;
    opacity: 1 !important;
    transition: opacity 0.2s !important;
    width: 24px !important;
    height: 24px !important;
  }

  :global(.drag-handle:hover) {
    opacity: 1;
    color: #475569;
  }

  :global(.drag-handle svg) {
    display: block;
    width: 100%;
    height: 100%;
  }

  :global(.tippy-box) {
    background: none;
    border: none;
    padding: 0;
  }

  :global(.tippy-arrow) {
    color: white;
  }

  :global(.editor-content) {
    padding-left: 32px;
    max-width: 65ch;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.75;
    font-size: 1rem;
    background-color: white;
    max-height: 650px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }

  :global(.ProseMirror.editor-content) {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (min-width: 640px) {
    :global(.editor-content) {
      font-size: 0.875rem;
    }
  }

  @media (min-width: 1024px) {
    :global(.editor-content) {
      font-size: 1.125rem;
    }
  }

  @media (min-width: 1280px) {
    :global(.editor-content) {
      font-size: 1.25rem;
    }
  }

  :global(.editor-content:focus, .ProseMirror:focus) {
    outline: none;
  }

  .html-editor {
    border-radius: 4px;
    overflow: hidden;
    height: 650px;
  }

  :global(.cm-editor) {
    height: 100%;
  }

  :global(.cm-editor .cm-scroller) {
    font-family: "Fira Code", monospace;
    font-size: 14px;
    line-height: 1.5;
  }

  :global(div[data-youtube-video] iframe) {
    border: none;
  }

  :global(.tableWrapper table) {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse !important;
    border-radius: 0px !important;
    border: 1px solid #e2e8f0 !important;
  }

  :global(.tableWrapper table th, .tableWrapper table td) {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #e2e8f0;
  }

  :global(.tableWrapper table thead th) {
    vertical-align: bottom;
    border-bottom: 1px solid #e2e8f0;
    background-color: #f8fafc;
    font-weight: 600;
    color: #475569;
  }

  :global(.tableWrapper table tbody + tbody) {
    border-top: 1px solid #e2e8f0;
  }

  :global(.tableWrapper table th, .tableWrapper table td) {
    border: 1px solid #e2e8f0;
    transition: background-color 0.2s ease;
  }

  :global(.tableWrapper table thead th, .tableWrapper table thead td) {
    border-bottom-width: 1px;
  }

  :global(.ProseMirror p.is-editor-empty:first-child::before) {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  :global(.ProseMirror h1.is-empty::before, .ProseMirror h2.is-empty::before, .ProseMirror h3.is-empty::before) {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .editor-footer {
    border-top: 1px solid #ccc;
    padding: 8px;
    font-size: 0.875rem;
    color: #666;
    display: flex;
    justify-content: flex-end;
  }

  .character-count {
    font-family: monospace;
    color: #2700ff;
    font-size: 11px;
  }

  .limit-info {
    color: #888;
  }

  :global(.ProseMirror.character-count--limited) {
    border-color: #ff6b6b;
  }
  :global(.table-container-wrapper) {
    background: var(--bg-clr-alt);
    border-radius: 0 0 8px 8px;
    margin: 0 0 1rem 0;
    padding: 20px;
    position: relative;
  }
  :global(.table-container) {
    padding: 0;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  :global(.table-element) {
    border-collapse: separate;
    border-spacing: 4px 2px;
    font-size: 1rem;
    border-radius: var(--bd-rad-sm);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 4px 2px;
    font-size: 1rem;
    border-radius: var(--bd-rad-sm);
    white-space: nowrap;
  }
  :global(.table-container .table-element p) {
    margin: 0;
  }
  :global(.table-element thead) {
    background: var(--bg-clr);
    color: black;
    font-size: 1.125rem;
    font-weight: 700;
  }

  :global(.table-element tbody tr td, .table-element tbody tr th) {
    background: var(--bg-clr);
    padding: 0.625rem 0.9375rem;
    font-size: 1.125rem;
    font-weight: 300;
  }

  :global(.table-element tbody tr:last-of-type td) {
    border-bottom-left-radius: var(--bd-rad-sm);
    border-bottom-right-radius: var(--bd-rad-sm);
  }
  :global(.pre-table-title) {
    background-color: #e54038;
    padding: 24px;
    border-radius: 8px 8px 0 0;
    font-size: 22px;
    font-weight: 700;
    color: white;
  }

  :global(.fire) {
    list-style-type: none;
    padding-left: 1.5em;
  }

  :global(.fire li) {
    position: relative;
  }

  :global(.fire li::before) {
    position: absolute;
    left: -2rem;
    top: 0.125rem;
    font-weight: 500;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IlZlY3RvciI+CjxwYXRoIGlkPSJWZWN0b3JfMiIgZD0iTTExLjQyODYgMTAuMjg0MUMxMS40Mjg2IDkuMjU0MTUgMTEuMTQ2NyA4LjI4ODA2IDEwLjY1NzMgNy40NDk4OEMxMC41NzEgNy42MTk0MSAxMC40ODE0IDcuNzc0MjUgMTAuMzg5OCA3LjkxNzEyQzkuNDMzOTEgOS40MDg1MSA4LjI1NDMyIDkuNTA5ODQgOC4yNTQzMiA5LjUwOTg0QzguNjY5MzQgOC40MTkwOSA4LjgwNDcwIDcuMzk0NTggOC43MDU3NCA2LjQzMDI4QzguNjUyNiA1LjkxMjU1IDguNTMyMTQgNS40MTIxNCA4LjM1MDc4IDQuOTI4MjZDNy4zNTcwMiAyLjI4NTg4IDQuOTE2OTkgMC4zNDY2NzYgNC43NjEyOCAwLjE5MDQzMEM1LjMzOTczIDMuNTkyODYgMy4xNjY3NiA1LjA5ODc0IDEuNzY1MDYgNi43NDY3MEMxLjA5NjEwIDcuNTM4OTYgMC41ODcyOCA4LjM0OTg0IDAuMjkxNjA5IDkuMjQ2NThDMC4xMDMzNjkgOS43NzA1NyAwIDEwLjMzMDQgMCAxMC45MTMwQzAgMTAuOTMxOCAwLjAwMTI5NDgxIDEwLjk1MDQgMC4wMDE1MDIxMyAxMC45NjkyQzAuMDAwNjg2MjI0IDExLjAxMDYgMCAxMS4wNTIgMCAxMS4wOTM5QzAgMTIuMzcwMiAwLjU2NDkzMyAxMy41NDExIDEuNDQ4NDQgMTQuNDI3MkMyLjQ5MjA2IDE1LjUwODkgNC4wMDkyMiAxNi4xOTA0IDUuNjk4ODcgMTYuMTkwNEM4Ljg0NjMgMTYuMTkwNCAxMS4zOTc4IDEzLjgyNzYgMTEuMzk3OCAxMC45MTMwQzExLjM5NzggMTAuODk4OSAxMS4zOTY4IDEwLjg1IDExLjM5NjcgMTAuODcwOUMxMS40MTcgMTAuNjc3OCAxMS40Mjg2IDEwLjQ4MjMgMTEuNDI4NiAxMC4yODQxWiIgZmlsbD0iI0U1NDAzOCIvPgo8L2c+Cjwvc3ZnPg==);
  }

  :global(.fire li p) {
    margin: 0;
  }

  :global(.pros) {
    list-style-type: none;
    padding-left: 1.5em;
  }

  :global(.pros li) {
    position: relative;
  }

  :global(.pros li::before) {
    position: absolute;
    left: -2rem;
    top: 0.125rem;
    font-weight: 500;
    content: "";
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><path d="M4 12.6111L8.92308 17.5L20 6.5"/></svg>');
    background-color: #69cc29;
    border-radius: 100%;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
  }

  :global(.pros li p) {
    margin: 0;
  }
  :global(.cons) {
    list-style-type: none;
    padding-left: 1.5em;
  }

  :global(.cons li) {
    position: relative;
  }

  :global(.cons li::before) {
    position: absolute;
    left: -2rem;
    top: 0.125rem;
    font-weight: 500;
    content: "";
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" fill="none" stroke="white" stroke-width="2"/></svg>');
    background-color: #e54038;
    border-radius: 100%;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
  }

  :global(.cons li p) {
    margin: 0;
  }

  :global(.focus-panel .table-container-wrapper) {
    background: none;
    padding: 0;
  }

  :global(.ProseMirror p:empty::before) {
    content: "";
    display: inline-block;
  }
</style>
