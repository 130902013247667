<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import BoldIcon from 'virtual:icons/heroicons/bold';
    import ItalicIcon from 'virtual:icons/heroicons/italic';
    import UnderlineIcon from 'virtual:icons/heroicons/underline';
    import StrikeThroughIcon from 'virtual:icons/heroicons/strikethrough';
    import HighlightIcon from 'virtual:icons/heroicons/paint-brush-16-solid';
    import CodeIcon from 'virtual:icons/heroicons/code-bracket';
    import TextAlignLeftIcon from 'virtual:icons/mdi/format-align-left';
    import TextAlignCenterIcon from 'virtual:icons/mdi/format-align-center';
    import TextAlignRightIcon from 'virtual:icons/mdi/format-align-right';
    import BulletListIcon from 'virtual:icons/mdi/format-list-bulleted';
    import OrderedListIcon from 'virtual:icons/mdi/format-list-numbered';
    import QuoteIcon from 'virtual:icons/mdi/format-quote-open';
    import UndoIcon from 'virtual:icons/heroicons/arrow-uturn-left';
    import RedoIcon from 'virtual:icons/heroicons/arrow-uturn-right';
    import LinkIcon from 'virtual:icons/heroicons/link';
    import ExternalLinkIcon from 'virtual:icons/heroicons/arrow-top-right-on-square';
    import ImageIcon from 'virtual:icons/heroicons/photo';
    import VideoIcon from 'virtual:icons/mdi/youtube';
    import ChevronUpIcon from 'virtual:icons/heroicons/chevron-up';
    import ChevronDownIcon from 'virtual:icons/heroicons/chevron-down';
    import TableLargePlusIcon from 'virtual:icons/mdi/table-large-plus';
    import TableLargeRemoveIcon from 'virtual:icons/mdi/table-large-remove';
    import ColumnBeforeIcon from 'virtual:icons/mdi/table-column-plus-before';
    import ColumnAfterIcon from 'virtual:icons/mdi/table-column-plus-after';
    import ColumnRemoveIcon from 'virtual:icons/mdi/table-column-remove';
    import RowBeforeIcon from 'virtual:icons/mdi/table-row-plus-before';
    import RowAfterIcon from 'virtual:icons/mdi/table-row-plus-after';
    import DeleteRowIcon from 'virtual:icons/mdi/table-row-remove';
    import TableHeaderEyeIcon from 'virtual:icons/mdi/table-headers-eye';
    import PreTableDivIcon from 'virtual:icons/mdi/page-layout-header';

    export let editor;
    export let compactMode = false;
    export let showFullMenu = true;
    export let showHtml = false;
    
    const dispatch = createEventDispatcher();
    let isTableActive = false;
    let hasTableHeader = false;
    let canUndo = false;
    let canRedo = false;
    let openLinksInNewWindow = false;
    let isLinkActive = false;
    let isExternalLink = false;

    const addImage = () => {
        const url = window.prompt('URL');
        if (url) {
            editor?.chain().focus().setImage({ src: url }).run();
        }
    };

    const addLink = () => {
        const previousUrl = editor?.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);
        if (url === null) {
            return;
        }
        
        if (url === '') {
            editor?.chain().focus().extendMarkRange('link').unsetLink().run();
            return;
        }
        
        try {
            const linkOptions = {
                href: url
            };
            
            if (openLinksInNewWindow) {
                Object.assign(linkOptions, { target: '_blank' });
            }
            
            editor?.chain().focus().extendMarkRange('link').setLink(linkOptions).run();
        } catch (e: unknown) {
            alert(e instanceof Error ? e.message : 'An unknown error occurred');
        }
    };

    const addYoutube = () => {
        const url = window.prompt('Enter YouTube URL');
        if (url) {
            editor?.chain().focus().setYoutubeVideo({
                src: url,
                width: 640,
                height: 480,
            }).run();
        }
    };

    const toggleOpenInNewWindow = () => {
        openLinksInNewWindow = !openLinksInNewWindow;
        
        if (editor) {
            editor.chain().focus().extendMarkRange('link').run();
            if (editor.isActive('link')) {
                const linkAttrs = editor.getAttributes('link');
                
                const linkOptions = {
                    ...linkAttrs,
                };
                
                if (openLinksInNewWindow) {
                    Object.assign(linkOptions, { target: '_blank' });
                } else {
                    delete linkOptions.target;
                }
                
                editor.chain().focus().extendMarkRange('link').setLink(linkOptions).run();
            }
        }
    };

    const updateStates = () => {
        if (editor) {
            isTableActive = editor.isActive('table');
            hasTableHeader = editor.isActive('table', { hasHeader: true });
            canUndo = editor.can().undo();
            canRedo = editor.can().redo();
            
            isLinkActive = editor.isActive('link');
            
            if (isLinkActive) {
                const linkAttrs = editor.getAttributes('link');
                isExternalLink = linkAttrs.target === '_blank';
                
                openLinksInNewWindow = isExternalLink;
            }
        }
    };

    $: if (editor) {
        updateStates();
        editor.on('transaction', updateStates);
    }
</script>

<div class="menu-bar">
    {#if editor}
        <div class="menu-group">
            <button
                on:click={() => editor.chain().focus().toggleBold().run()}
                class:active={editor?.isActive('bold')}
                title="Bold">
                <BoldIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleItalic().run()}
                class:active={editor?.isActive('italic')}
                title="Italic">
                <ItalicIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleUnderline().run()}
                class:active={editor?.isActive('underline')}
                title="Underline">
                <UnderlineIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleStrike().run()}
                class:active={editor?.isActive('strike')}
                title="Strike">
                <StrikeThroughIcon />
            </button>
        </div>

        <div class="menu-group">
            <button
                on:click={() => editor.commands.undo()}
                disabled={!canUndo}
                title="Undo">
                <UndoIcon />
            </button>
            <button
                on:click={() => editor.commands.redo()}
                disabled={!canRedo}
                title="Redo">
                <RedoIcon />
            </button>
        </div>

        <div class="menu-group">
            <button
                on:click={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                class:active={editor?.isActive('heading', { level: 1 })}
            >
                H1
            </button>
            <button
                on:click={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                class:active={editor?.isActive('heading', { level: 2 })}
            >
                H2
            </button>
            <button
                on:click={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                class:active={editor?.isActive('heading', { level: 3 })}
            >
                H3
            </button>
        </div>

        <div class="menu-group">
            <button
                on:click={() => editor.chain().focus().toggleBulletList().run()}
                class:active={editor?.isActive('bulletList')}
                title="Bullet List">
                <BulletListIcon style="font-size: 1.4em" />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleFireList().run()}
                class:active={editor?.isActive('fireList')}
                title="Fire List">
                <BulletListIcon style="font-size: 1.4em; color: #ff4400;" />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleProsList().run()}
                class:active={editor?.isActive('checkList')}
                title="Pros List">
                <BulletListIcon style="font-size: 1.4em; color: #22c55e;" />
            </button>
            <button
            on:click={() => editor.chain().focus().toggleConsList().run()}
            class:active={editor?.isActive('checkList')}
            title="Cons List">
            <BulletListIcon style="font-size: 1.4em; color: orange;" />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleOrderedList().run()}
                class:active={editor?.isActive('orderedList')}
                title="Numbered List">
                <OrderedListIcon style="font-size: 1.4em" />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleBlockquote().run()}
                class:active={editor?.isActive('blockquote')}
                title="Quote">
                <QuoteIcon style="font-size: 1.4em" />
            </button>
        </div>

        <div class="menu-group">
            <button
                on:click={() => editor.chain().focus().setTextAlign('left').run()}
                class:active={editor?.isActive({ textAlign: 'left' })}
                title="Align Left">
                <TextAlignLeftIcon style="font-size: 1.2em" />
            </button>
            <button
                on:click={() => editor.chain().focus().setTextAlign('center').run()}
                class:active={editor?.isActive({ textAlign: 'center' })}
                title="Align Center">
                <TextAlignCenterIcon style="font-size: 1.2em" />
            </button>
            <button
                on:click={() => editor.chain().focus().setTextAlign('right').run()}
                class:active={editor?.isActive({ textAlign: 'right' })}
                title="Align Right">
                <TextAlignRightIcon style="font-size: 1.2em" />
            </button>
        </div>

        <div class="menu-group">
            <button on:click={addLink} class:active={isLinkActive} title="Add Link">
                <LinkIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().unsetLink().run()}
                disabled={!isLinkActive}
                title="Remove Link">
                <LinkIcon />
            </button>
            <button
                on:click={toggleOpenInNewWindow}
                class:active={isExternalLink}
                disabled={!isLinkActive}
                title={openLinksInNewWindow ? "Links open in new window" : "Links open in same window"}>
                <ExternalLinkIcon />
            </button>
        </div>

        <div class="menu-group">
            <button
                on:click={() => editor.chain().focus().toggleHighlight().run()}
                class:active={editor?.isActive('highlight')}
                title="Highlight">
                <HighlightIcon style="font-size: 1.2em;" />
            </button>
            <input
                type="color"
                placeholder="Noffa"
                on:input={({ currentTarget }) => 
                    editor.chain().focus().setColor(currentTarget.value).run()
                }
                title="Text Color"
            />
        </div>

        <div class="menu-group">
            <button on:click={addImage} title="Add Image">
                <ImageIcon style="font-size: 1.2em;" />
            </button>
            <button on:click={addYoutube} title="Add YouTube Video">
                <VideoIcon style="font-size: 1.4em;" />
            </button>
            <button 
                on:click={() => dispatch('toggleHtml')} 
                class:active={showHtml} 
                title="View HTML">
                <CodeIcon />
            </button>
        </div>

        <div class="menu-group">
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withTitle: true }).run()}
                title="Insert Table">
                <TableLargePlusIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().deleteTable().run()}
                disabled={!isTableActive}
                title="Delete table">
                <TableLargeRemoveIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().addColumnBefore().run()}
                disabled={!isTableActive}
                title="Add column before">
                <ColumnBeforeIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().addColumnAfter().run()}
                disabled={!isTableActive}
                title="Add column after">
                <ColumnAfterIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().deleteColumn().run()}
                disabled={!isTableActive}
                title="Delete column">
                <ColumnRemoveIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().addRowBefore().run()}
                disabled={!isTableActive}
                title="Add row before">
                <RowBeforeIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().addRowAfter().run()}
                disabled={!isTableActive}
                title="Add row after">
                <RowAfterIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                on:click={() => editor.chain().focus().deleteRow().run()}
                disabled={!isTableActive}
                title="Delete row">
                <DeleteRowIcon style="font-size: 1.4em" />
            </button>
            <button
                class="menu-item"
                disabled={!isTableActive}
                title="Toggle Pre Table Title Div"
                on:click={() => editor.chain().focus().togglePreTableTitleDiv().run()}
            >
                <PreTableDivIcon style="font-size: 1.4em" />
            </button>
        </div>

        {#if compactMode}
            <div class="menu-group menu-toggle">
                <button 
                    on:click={() => dispatch('toggleMenu')} 
                    title={showFullMenu ? 'Show Less' : 'Show More'}>
                    {#if showFullMenu}
                        <ChevronUpIcon />
                    {:else}
                        <ChevronDownIcon />
                    {/if}
                </button>
            </div>
        {/if}
    {/if}
</div>

<style>
    .menu-bar {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 0.5rem;
        background-color: #f8f9fa;
        border-radius: 0.25rem;
    }

    .menu-group {
        display: flex;
        gap: 0.25rem;
        padding: 0.25rem;
        border-right: 1px solid #dee2e6;
        flex-wrap: wrap;
    }

    .menu-group:last-child {
        border-right: none;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
        height: 2rem;
        padding: 0.375rem;
        background: none;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        color: #495057;
        cursor: pointer;
        transition: all 0.2s;
    }

    button:hover {
        background-color: #e9ecef;
        border-color: #dee2e6;
    }

    button.active {
        background-color: #e9ecef;
        border-color: #ced4da;
        color: #212529;
    }

    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    input[type="color"] {
        width: 2rem;
        height: 2rem;
        padding: 2px;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
        border: 1px solid #ced4da;
    }

    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    input[type="color"]::-webkit-color-swatch {
        border: none;
        border-radius: 0.25rem;
    }

    .compact {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .toggle-menu {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 0.5rem;
    }

    :global(.table-header) {
        margin-bottom: 1rem;
        padding: 0.5rem;
        background-color: #f3f4f6;
        border-radius: 0.375rem;
    }

    :global(.table-title) {
        margin: 0;
        font-size: 1.125rem;
        font-weight: 600;
        color: #374151;
        outline: none;
    }

    :global(.table-title:empty::before) {
        content: attr(data-placeholder);
        color: #9ca3af;
    }
</style>
